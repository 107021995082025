<script setup lang="ts">
import { useLinks } from "../composables/useLinks";

const props = defineProps<{
  blok: StoryblokButton;
}>();

const { preparedUrl, trackClickEvent } = useLinks(props.blok.link);
</script>

<template>
  <div
    v-editable="blok"
      :class="[
        'flex',
        { 'justify-start': blok.alignment === 'start' },
        { 'justify-center': blok.alignment === 'center' },
        { 'justify-end': blok.alignment === 'end' },
      ]"
    >
      <BaseButton
        :is-primary="true"
        :is-story="blok?.link.linktype === 'story'"
        :href="preparedUrl"
        @click="trackClickEvent"
        >{{ blok.text }}</BaseButton
      >
  </div>
</template>
